import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

import * as styles from "./index.module.scss";

import SlideSvg from "../../images/testimonials-bg.inline.svg";
import testimonialPhoto from "../../images/testimonial photo@3x.png";
import testimonialPhoto768 from "../../images/testimonial photo@2x.png";
import testimonialPhoto320 from "../../images/testimonial photo.png";

import BackgroundImage from "../BackgroundImage";
import Slide from "../Slide";

const Slider = () => {
  const sliderRef = React.useRef({
    activeIndex: 0,
    slideNext: () => {},
    slidePrev: () => {},
  });

  const onNextSlide = React.useCallback(() => {
    sliderRef.current.slideNext();
  }, []);

  const onPrevSlice = React.useCallback(() => {
    sliderRef.current.slidePrev();
  }, []);

  return (
    <BackgroundImage Svg={SlideSvg}>
      <div className={styles.swiperWrapper}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={(swiper) => (sliderRef.current = swiper)}
          ref={sliderRef}
        >
          <SwiperSlide>
            <Slide
              text={`"I love Fearn so much! It's a game changer It was a great experience for me to cooperate with Fearn"`}
              notice="- Taylor, Full Time Reseller"
              backgroundImageDesktop={testimonialPhoto}
              backgroundImageTablet={testimonialPhoto768}
              backgroundImageMobile={testimonialPhoto320}
              imageAlt=""
              onPrev={onPrevSlice}
              onNext={onNextSlide}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              text={`"I love Fearn so much! It's a game changer It was a great experience for me to cooperate with Fearn"`}
              notice="- Taylor, Full Time Reseller"
              backgroundImageDesktop={testimonialPhoto}
              backgroundImageTablet={testimonialPhoto768}
              backgroundImageMobile={testimonialPhoto320}
              imageAlt=""
              onPrev={onPrevSlice}
              onNext={onNextSlide}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </BackgroundImage>
  );
};

export default Slider;
