import * as React from "react";
import PropTypes from "prop-types";

import * as styles from "./index.module.scss";

import SlideButton from "./components/SlideButton";
import Picture from "../Picture";

const Slide = ({ text, notice, backgroundImageDesktop, backgroundImageTablet, backgroundImageMobile, imageAlt, onPrev, onNext }) => {

  return (
    <div className={styles.slideWrapper}>
      <SlideButton onClick={onPrev} isNext={false} />
      <div className={styles.contentWrapper}>
        <div className={styles.textWrapper}>
          <span className={styles.text}>{text}</span>
          <span className={styles.notice}>{notice}</span>
        </div>
          <Picture
              backgroundImageDesktop={backgroundImageDesktop}
              backgroundImageTablet={backgroundImageTablet}
              backgroundImageMobile={backgroundImageMobile}
              imageStyle={styles.image}
              alt={imageAlt}
          />
      </div>
      <SlideButton onClick={onNext} isNext={true} />
    </div>
  );
};

Slide.propTypes = {
  text: PropTypes.string,
  notice: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
};

export default Slide;
