import * as React from "react";

import * as styles from "./index.module.scss";

import SectionLayout from "../../../components/SectionLayout";
import Carousel from "../../../components/Carousel";

const CarouselSection = () => {
  return (
    <SectionLayout className={styles.carouselSectionWrapper}>
      <Carousel />
    </SectionLayout>
  );
};

export default CarouselSection;
