import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import * as styles from "./index.module.scss";
import image from "../../../../../static/landing-next-arrow-active.svg";

const SlideButton = ({ onClick, isNext }) => {
  return (
    <div className={styles.buttonWrapper}>
      <button className={styles.button} type="button" onClick={onClick}>
        <img
          className={classnames({ [styles.prevImage]: !isNext })}
          src={image}
          alt="Slide button"
        />
      </button>
    </div>
  );
};

SlideButton.propTypes = {
  onClick: PropTypes.func,
  isNext: PropTypes.bool,
};

export default SlideButton;
