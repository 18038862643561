import * as React from "react";

import * as styles from "./index.module.scss";
import featureImage1 from "../../../../static/illustration-alleviate.png";
import featureImage2 from "../../../../static/illustration-crosslisting.png";
import featureImage3 from "../../../../static/illustration-maximize.png";
import featureImage4 from "../../../../static/illustration-analyze.png";

import SectionLayout from "../../../components/SectionLayout";
import Feature from "../../../components/Feature";
import CarouselSection from "../Carousel";

const FeaturesSection = () => {
  return (
    <SectionLayout className={styles.featuresSectionWrapper}>
      <Feature
        imageUrl={featureImage1}
        title="Alleviate the stress of bookkeeping"
        text="Remove the guesswork, cut out the overwhelm & get organized. Automated sales reporting, expense tracking, income statements & more (yipee!)"
        textToRight={true}
        className={styles.featuresSection}
      />
      <Feature
        imageUrl={featureImage4}
        title="Reap the rewards of crosslisting"
        text="The easiest way to increase sales is to leverage your existing inventory on multiple platforms. We make it easy to not only crosslist, but to import, edit, delist, and relist as well. Plus, we are constantly adding new platforms!"
        textToRight={false}
      />
      <CarouselSection />
      <Feature
        imageUrl={featureImage2}
        title="Streamline systems with automation"
        text="Focus on your business, not the busy-work. With the click of a button, you can import inventory, delist from multiple platforms, and expedite your shipping."
        textToRight={true}
      />
      <Feature
        imageUrl={featureImage3}
        title="Power your decision making through analytics"
        text="Know your numbers, know your business. Measure your performance and implement data-driven adjustments."
        textToRight={false}
      />
    </SectionLayout>
  );
};

export default FeaturesSection;
